<template>
  <div class="sidebar-detached sidebar-left store-side-bar">
    <div class="sidebar">
      <div
        class="sidebar-shop fs-16px"
        :class="{ show: mqShallShowLeftSidebar }"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block fs-16px">
              {{ $t('Filters') }}
            </h6>
          </b-col>
        </b-row>
        <div
          class="wrap-sidebar-filters"
          :class="{ card: filterOptions.showAllInCard }"
        >
          <div
            v-if="showFilterClose"
            class="col-md-12 text-right pt-2 close-filter-button"
          >
            <feather-icon
              icon="XIcon"
              size="21"
              @click="$emit('hide-filter-option')"
            />
          </div>
          <b-card v-if="filterOptions.showServiceLocation">
            <div class="multi-range-price">
              <h6 class="filter-title mt-0">
                {{ $t('Service Location') }}
              </h6>
              <b-form-radio-group
                v-model="filterOptions.serviceLocationFilter"
                class="price-range-defined-radio-group"
                stacked
                :options="filterOptions.serviceLocation"
              />
            </div>
          </b-card>
          <b-card v-if="false && filterOptions.quickFilters && filterOptions.quickFilters.length > 0">
            <div class="multi-range-price">
              <h6 class="filter-title mt-0">
                {{ filterOptions.quickFiltersTitle ? filterOptions.quickFiltersTitle : $t('Quick Filters') }}
              </h6>
              <b-form-radio-group
                v-model="filterOptions.selectedQuickFilter"
                class="price-range-defined-radio-group"
                stacked
                :options="filterOptions.quickFilters"
              />
            </div>
          </b-card>
          <div v-if="filterOptions.categoryFilters && filterOptions.categoryFilters.length > 0">
            <div class="multi-range-price fs-16px">
              <h6 class="filter-title mt-0 fs-16px">
                {{ $t('Categories') }}
              </h6>
              <b-form-radio-group
                v-model="filterOptions.selectedCategory"
                class="price-range-defined-radio-group"
                stacked
                :options="filterOptions.categoryFilters"
              />
            </div>
          </div>
          <b-card v-if="filterOptions.showPriceRangeSlider">
            <div class="multi-range-price">
              <h6 class="filter-title mt-0">
                {{ $t('Price Range') }}
              </h6>
              <!-- <vue-slider
                v-model="filterOptions.priceRangeSliderValue"
                :lazy="true"
                :direction="'ltr'"
              /> -->
            </div>
          </b-card>
          <b-card v-if="filterOptions.showPriceRange">
            <div class="multi-range-price">
              <h6 class="filter-title mt-0">
                {{ $t('Price Range') }}
              </h6>
              <price-range-drop-down @price-range-change="priceRangeChange" />
            </div>
          </b-card>
          <div class="text-center mb-2">
            <b-button
              v-if="filterOptions.showClearAllButton"
              variant="primary"
              @click="$emit('clear-all-filters')"
            >
              {{ $t('Clear All Filters') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { BRow, BCol, BCard, BFormRadioGroup, BButton } from 'bootstrap-vue'
// import VueSlider from 'vue-slider-component'
import PriceRangeDropDown from '@/@core/layouts/shared/PriceRangeDropDown.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BCard,
    PriceRangeDropDown,
    // VueSlider,
    BButton,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    filterOptions: {
      type: Object,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    showFilterClose: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    priceRangeChange(changeValue) {
      this.$emit('price-range-change', changeValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.wrap-sidebar-filters.card .card {
  margin-bottom: 0;
}
</style>
