<template>
  <b-form-group
    :label="labelText"
    class="price-range-dropdown"
  >
    <v-select
      v-model="priceRange"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="optionsLabel"
      :reduce="val => val.value"
      :clearable="false"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormGroup,
  },
  props: {
    labelText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      priceRange: '',
      optionsLabel: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Not to Say',
          value: 'NOT_TO_SAY',
        },
        {
          label: 'In-Expensive',
          value: 'INEXPENSIVE',
        },
        {
          label: 'Moderate',
          value: 'MODERATE',
        },
        {
          label: 'Pricey',
          value: 'PRICEY',
        },
        {
          label: 'Ultra High',
          value: 'ULTRA_HIGH',
        },
      ],
    }
  },
  watch: {
    priceRange: {
      handler() {
        this.priceRangeChanged()
      },
    },
  },
  methods: {
    priceRangeChanged() {
      this.$emit('price-range-change', this.priceRange)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
